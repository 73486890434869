import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Body from '../components/body'

class RootIndex extends React.Component {
  render() {
    const {
      title,
      subtitle,
      body: { raw, references },
    } = get(this, 'props.data.contentfulHome')

    return (
      <Layout largeHeader heroProps={{ title, subtitle }}>
        <Helmet title="Home" />
        <Body content={JSON.parse(raw)} references={references} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHome {
      title
      subtitle
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            publicUrl
          }
          gatsbyImageData
        }
      }
    }
  }
`
